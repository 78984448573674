import { createRef, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import xss from 'xss';
import FormError from 'components/FormError';
import FormInput from 'components/FormInput';
import Image from 'components/Image';
import LumeTooltip from 'components/LumeTooltip';
import logger from 'config/logger';
import { RequestInstance } from 'request';
import { initializeFavoriteProducts } from 'stores/actions/favorite-products';
import { setUserInfo } from 'stores/actions/user';
import formatDate from 'utils/format-date';
import formatPhoneNumber from 'utils/format-phone-number';
import generateEncodedLoyaltyConsent from 'utils/generate-encoded-loyalty-consent';
import isAfterNow from 'utils/is-after-now';
import isDate from 'utils/is-date';
import isLegalAge from 'utils/is-legal-age';
import isZipCode from 'utils/is-zip-code';
import { sendPageView } from 'utils/metrics';
import validatePassword from 'utils/validate-password';
import styles from './CreateAccount.module.scss';

const verifyEmailInstructions =
  'Please verify your email address before signing in. A link to verify has just been sent to your inbox.';
const phoneValidations = [(value) => value.replace(/\D/g, '').length === 10];
const dateValidations = [(value) => !isAfterNow(value), isDate, isLegalAge];
const passwordValidations = [validatePassword];
const zipCodeValidations = [(value) => value === '' || isZipCode(value)];

export default function CreateAccount({
  createAccountContent = {},
  createAnAccountLabel,
  handleOpenLogin,
  handleOpenMyAccount,
  onConfirmationPage = false,
  onLoginSuccess,
}) {
  const dispatch = useDispatch();
  const [attemptingCreate, setAttemptingCreate] = useState(false);
  const [validated, setValidated] = useState(false);
  const [password, setPassword] = useState('');
  const [formError, setFormError] = useState(null);
  const [showValidationInstructions, setShowValidationInstructions] =
    useState(false);
  const confirmPasswordRef = useRef(null);

  useEffect(() => {
    sendPageView('Create Account');
  }, []);

  const {
    already_have_an_account_label: alreadyHaveAnAccountLabel,
    confirm_password_invalid: confirmPasswordInvalid,
    confirm_password_label: confirmPasswordLabel,
    confirm_password_placeholder: confirmPasswordPlaceholder,
    confirmation_page_header: confirmationPageHeader,
    date_of_birth_label: dateOfBirthLabel,
    date_of_birth_required: dateOfBirthRequired,
    date_of_birth_underage: dateOfBirthUnderAge,
    date_of_birth_invalid: dateOfBirthInvalid,
    disclosure_label: disclosureLabel,
    email_address_invalid: emailAddressInvalid,
    email_address_label: emailAddressLabel,
    email_address_placeholder: emailAddressPlaceholder,
    first_name_label: firstNameLabel,
    first_name_placeholder: firstNamePlaceholder,
    first_name_required: firstNameRequired,
    header,
    info_blurbs: infoBlurbs,
    last_name_label: lastNameLabel,
    last_name_placeholder: lastNamePlaceholder,
    last_name_required: lastNameRequired,
    login_label: loginLabel,
    loyalty_label: loyaltyLabel,
    loyalty_tooltip_info: loyaltyTooltipInfo,
    mobile_number_label: mobileNumberLabel,
    mobile_number_required: mobileNumnerRequired,
    password_instructional_text: passwordInstructionalText,
    password_label: passwordLabel,
    password_placeholder: passwordPlaceholder,
    password_invalid: passwordInvalid,
    terms_and_conditions_label: termsAndConditionsLabel,
    zip_code_label: zipCodeLabel,
    zip_code_placeholder: zipCodePlaceholder,
    zip_code_invalid: zipCodeInvalid,
  } = createAccountContent;

  const attemptCreateAccount = async ({
    birthdate,
    confirmPassword,
    email,
    familyName,
    givenName,
    loyaltyConsentData,
    password,
    phoneNumber,
    subscribe,
    zipCode,
  }) => {
    try {
      setAttemptingCreate(true);
      const { success } =
        /* eslint-disable camelcase */
        await RequestInstance.post('api/account/create', {
          json: {
            birthdate,
            confirm_password: confirmPassword,
            email,
            family_name: familyName,
            given_name: givenName,
            loyalty_consent_data: loyaltyConsentData,
            password,
            phone_number: phoneNumber,
            subscribe,
            zip_code: zipCode,
          },
        });
      /* eslint-enable camelcase */

      if (success) {
        const { userInfo } = await RequestInstance.post('api/account/login', {
          json: { username: email, password, remember: false },
        });

        if (userInfo) {
          if (onLoginSuccess) {
            onLoginSuccess(userInfo);
          }
          dispatch(setUserInfo(userInfo));
          dispatch(initializeFavoriteProducts());
          if (handleOpenMyAccount) {
            handleOpenMyAccount();
          }
        }

        setFormError(null);
        setShowValidationInstructions(true);
      }
    } catch (err) {
      if (err?.message) {
        setFormError(err?.message);
      }

      logger.error(err, 'Error creating account');
    } finally {
      setAttemptingCreate(false);
    }
  };

  const onSubmit = (event) => {
    event.stopPropagation();
    event.preventDefault();
    try {
      const {
        confirm_password: { value: confirmPassword },
        create_account_email_address: { value: email },
        create_account_password: { value: password },
        create_loyalty_account: { checked: createLoyaltyAccount },
        date_of_birth: { value: birthdate },
        first_name: { value: givenName },
        last_name: { value: familyName },
        mobile_number: { value: phoneNumber },
        subscribe_for_updates: { checked: subscribe },
        zip_code: { value: zipCode },
      } = event.target.elements;

      const formattedDob = dayjs(birthdate).format('YYYY-MM-DD');

      const loyaltyConsentData = createLoyaltyAccount
        ? generateEncodedLoyaltyConsent({
            birthdate,
            email,
            firstName: givenName,
            lastName: familyName,
            phoneNumber,
          })
        : undefined;

      attemptCreateAccount({
        birthdate: formattedDob,
        confirmPassword,
        email,
        familyName,
        givenName,
        loyaltyConsentData,
        password,
        phoneNumber,
        subscribe,
        zipCode,
      });
    } catch (err) {
      logger.error(err, 'Error in create account submit');
    }
    return false;
  };

  const formRef = createRef();
  const confirmPasswordValidations = [(value) => value === password];

  const validateForm = () => {
    try {
      const {
        confirm_password: { value: confirmPassword },
        create_account_password: { value: createAccountPassword },
        date_of_birth: { value: dateOfBirth },
        mobile_number: { value: mobileNumber },
        zip_code: { value: zipCode },
      } = formRef.current.elements;
      const htmlValidation = formRef.current.checkValidity();
      const isPasswordValid = passwordValidations.every((fn) =>
        fn(createAccountPassword)
      );
      const isConfirmPasswordValid = confirmPasswordValidations.every((fn) =>
        fn(confirmPassword)
      );
      const isDateOfBirthValid = dateValidations.every((fn) => fn(dateOfBirth));
      const isMobileNumberValid = phoneValidations.every((fn) =>
        fn(mobileNumber)
      );
      const isZipCodeValid = zipCodeValidations.every((fn) => fn(zipCode));
      setValidated(
        htmlValidation &&
          isPasswordValid &&
          isConfirmPasswordValid &&
          isDateOfBirthValid &&
          isMobileNumberValid &&
          isZipCodeValid
      );
    } catch (err) {
      logger.error(err, 'There was an error attempting validation');
      setValidated(false);
    }
  };

  const resetConfirmPasswordField = () => {
    const {
      confirm_password: { value: confirmPassword },
    } = formRef.current.elements;

    if (confirmPassword) {
      confirmPasswordRef.current.resetFormInput();
    }
  };

  return showValidationInstructions ? (
    <>
      <div
        id="account-create-validation-instructions-title"
        className="font-freight fs-16 lh-1 mb-8 mt-16 text-primary text-center"
      >
        Account Created!
      </div>
      <div
        className={classNames('fs-7', {
          'mb-16': onConfirmationPage,
          'mb-8': !onConfirmationPage,
        })}
      >
        {verifyEmailInstructions}
      </div>
      {!onConfirmationPage && (
        <Button
          className={`bg-white font-geo w-100 fs-9 mt-15 text-primary text-uppercase ${styles.cancel_button}`}
          id="back_to_login button"
          type="submit"
          variant="secondary"
          onClick={handleOpenLogin}
        >
          Back to Login
        </Button>
      )}
    </>
  ) : (
    <>
      <div
        id="account-create-title"
        className="font-freight fs-16 lh-1 mb-8 mt-16 text-primary text-center"
      >
        {showValidationInstructions
          ? 'Account Created!'
          : onConfirmationPage
          ? confirmationPageHeader
          : header}
      </div>
      {onConfirmationPage && infoBlurbs?.length > 0 && (
        <div className="d-flex flex-row mx-auto w-100 w-md-75 w-lg-50 w-xl-75 my-10">
          <ul
            className={classNames('list-unstyled', styles.info_blurb_container)}
          >
            {infoBlurbs.map((infoBlurb) => {
              const {
                blurb,
                icon: { title: iconTitle, url: iconSrc },
                title,
              } = infoBlurb;
              return (
                <div
                  key={title}
                  className="d-flex flex-column justify-content-start align-items-center"
                >
                  <Image alt={iconTitle} height={70} src={iconSrc} width={70} />
                  <span className={styles.info_blurb}>{blurb}</span>
                </div>
              );
            })}
          </ul>
        </div>
      )}
      {!onConfirmationPage && (
        <div className="fs-7 mb-8">
          <span>{alreadyHaveAnAccountLabel}</span>
          <button
            className={`bg-transparent border-0 fw-bold text-decoration-underline text-primary ${styles.link}`}
            type="button"
            onClick={handleOpenLogin}
          >
            {loginLabel}
          </button>
        </div>
      )}
      <div className="w-100 mb-15">
        <Form ref={formRef} id="create_account_form" onSubmit={onSubmit}>
          <fieldset>
            <FormInput
              required
              controlId="first_name"
              label={firstNameLabel}
              placeholder={firstNamePlaceholder}
              type="text"
              validateForm={validateForm}
              validationMessage={firstNameRequired}
            />
            <FormInput
              required
              controlId="last_name"
              label={lastNameLabel}
              placeholder={lastNamePlaceholder}
              type="text"
              validateForm={validateForm}
              validationMessage={lastNameRequired}
            />
            <FormInput
              required
              autoComplete="off"
              controlId="mobile_number"
              label={mobileNumberLabel}
              placeholder="(___) ___-____"
              type="tel"
              validateForm={validateForm}
              validationMessage={mobileNumnerRequired}
              validations={phoneValidations}
              onChange={(e) => {
                const node = e.target;
                if (e.nativeEvent.inputType) {
                  const { value } = node;
                  const { formatted, cursorPosition } = formatPhoneNumber(
                    value,
                    e
                  );
                  node.value = formatted;
                  node.setSelectionRange(cursorPosition, cursorPosition);
                }
              }}
            />
            <FormInput
              required
              className="text-start text-uppercase"
              controlId="date_of_birth"
              label={dateOfBirthLabel}
              max={dayjs().subtract(18, 'y').format('MM/DD/YYYY')}
              placeholder="MM/DD/YYYY"
              type="text"
              validations={dateValidations}
              validateForm={validateForm}
              validationMessage={(value) => {
                if (!isDate(value)) {
                  return dateOfBirthRequired;
                }
                if (isAfterNow(value)) {
                  return dateOfBirthInvalid;
                }
                if (!isLegalAge(value, 18)) {
                  return dateOfBirthUnderAge;
                }
                return '';
              }}
              onChange={(e) => {
                const node = e.target;
                if (e.nativeEvent.inputType) {
                  const { value } = node;
                  const { formatted, cursorPosition } = formatDate(value, e);
                  node.value = formatted;
                  node.setSelectionRange(cursorPosition, cursorPosition);
                }
              }}
            />
            <FormInput
              required
              controlId="create_account_email_address"
              label={emailAddressLabel}
              pattern="^\S+@\S+\.\S\S+$"
              placeholder={emailAddressPlaceholder}
              type="email"
              validateForm={validateForm}
              validationMessage={emailAddressInvalid}
            />
            <FormInput
              controlId="zip_code"
              label={zipCodeLabel}
              placeholder={zipCodePlaceholder}
              type="text"
              validateForm={validateForm}
              validationMessage={zipCodeInvalid}
              validations={zipCodeValidations}
            />
            <FormInput
              required
              aria-describedby="password-instructions"
              controlId="create_account_password"
              label={passwordLabel}
              placeholder={passwordPlaceholder}
              type="password"
              validateForm={validateForm}
              validationMessage={passwordInvalid}
              validations={passwordValidations}
              onChange={(e) => {
                setPassword(e.target.value);
                resetConfirmPasswordField();
              }}
            />
            <p
              className="fs-6 text-muted my-8 text-center"
              id="password-instructions"
            >
              {passwordInstructionalText}
            </p>
            <FormInput
              required
              controlId="confirm_password"
              formInputRef={confirmPasswordRef}
              label={confirmPasswordLabel}
              placeholder={confirmPasswordPlaceholder}
              type="password"
              validateForm={validateForm}
              validationMessage={confirmPasswordInvalid}
              validations={confirmPasswordValidations}
            />
            <Form.Group controlId="subscribe_for_updates" className="px-3">
              <Form.Check
                defaultChecked
                className={`fs-7 mt-8 ${styles.check_box}`}
                name="subscribe_for_updates"
                type="checkbox"
                label={disclosureLabel}
              />
            </Form.Group>
            <Form.Group controlId="create_loyalty_account" className="px-3">
              <Form.Check
                defaultChecked
                className={`fs-7 mt-8 ${styles.check_box}`}
                name="create_loyalty_account"
                type="checkbox"
                label={
                  <span className="d-flex flex-row">
                    <span className="pe-4">{loyaltyLabel}</span>
                    <LumeTooltip>
                      <span
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: xss(loyaltyTooltipInfo),
                        }}
                      />
                    </LumeTooltip>
                  </span>
                }
              />
            </Form.Group>
          </fieldset>
          <Button
            className="fs-9 mt-8 w-100"
            disabled={!validated || attemptingCreate}
            id="login_submit_button"
            type="submit"
            variant="secondary"
          >
            {createAnAccountLabel}
          </Button>
          <FormError message={formError} />
          <p className="fs-6 text-muted my-8 text-center">
            {termsAndConditionsLabel}
          </p>
        </Form>
      </div>
    </>
  );
}
