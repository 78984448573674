import { createSlice, createAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export const SLICE_NAME = 'storePromoCodes';
export const initialState = {
  storePromoCodes: {},
};

const storePromoCodesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setStorePromoCodes(state, action) {
      return { ...state, ...action.payload };
    },
  },
  extraReducers(builder) {
    builder.addCase(createAction(HYDRATE), (state, action) => {
      return {
        ...state,
        ...action.payload[SLICE_NAME],
      };
    });
  },
});

export default storePromoCodesSlice;
