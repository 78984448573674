import { memo } from 'react';
import classNames from 'classnames';

const FormError = memo(({ message, customCss = 'mt-8' }) => {
  return (
    message && (
      <div
        aria-live="assertive"
        className={classNames('bg-danger p-8 text-white', customCss)}
        role="alert"
      >
        {message}
      </div>
    )
  );
});

export default FormError;
