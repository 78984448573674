import { useEffect, useState } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';
import styles from './FormInput.module.scss';

const getAriaDescribedBy = ({ ariaDescribedBy, controlId, errorMessage }) => {
  const controlErrorId = errorMessage && `${controlId}-error`;
  const invalidAriaDescribedBy =
    [controlErrorId, ariaDescribedBy].filter(Boolean).join(' ') ?? null;
  const validAriaDescribedBy = ariaDescribedBy || null;
  return { invalidAriaDescribedBy, validAriaDescribedBy };
};

export default function FormInput({
  'aria-describedby': ariaDescribedBy,
  autoComplete,
  autoFocus,
  className = 'text-primary',
  controlId,
  defaultValue = '',
  disabled,
  feedbackStyles,
  formErrorMessage,
  formGroupClassName = 'border-primary',
  formInputRef,
  label,
  labelClassName = '',
  max,
  maxLength,
  min,
  minLength,
  onChange: passedOnChange,
  onIconClick,
  pattern,
  placeholder,
  readOnly,
  required,
  type,
  parentInvalid,
  validateForm,
  validationMessage,
  validations = [],
  ...rest
}) {
  const [inputValue, setInputValue] = useState(defaultValue);
  const [isInvalid, setIsInvalid] = useState(null);
  const [errorMessage, setErrorMessage] = useState(validationMessage);

  const validate = (e, setInvalidation = true) => {
    const { value } = e.currentTarget;
    const isCustomValid = validations.every((fn) => fn(value));
    const formFieldInvalid = !isCustomValid || !e.currentTarget.checkValidity();

    if (setInvalidation || !formFieldInvalid) {
      setIsInvalid(formFieldInvalid);
    }

    if (setInvalidation && formFieldInvalid) {
      if (typeof validationMessage === 'string') {
        setErrorMessage(validationMessage);
      } else if (typeof validationMessage === 'function') {
        setErrorMessage(validationMessage(value));
      }
    }

    if (validateForm) {
      validateForm();
    }
  };

  const resetFormInput = () => {
    setInputValue(defaultValue);
    setIsInvalid(null);
    setErrorMessage(validationMessage);
  };

  if (formInputRef) {
    formInputRef.current = { resetFormInput };
  }

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  const onBlur = (e) => {
    validate(e, true);
  };

  const onChange = (e) => {
    if (passedOnChange) {
      passedOnChange(e);
    }

    setInputValue(e.target.value);

    validate(e, false);
  };

  const formControlClass = classNames(
    'bg-transparent',
    'border-0',
    'lh-1',
    'p-8',
    className
  );

  const { invalidAriaDescribedBy, validAriaDescribedBy } = getAriaDescribedBy({
    ariaDescribedBy,
    controlId,
    errorMessage,
  });

  const groupClasses = [
    'bg-transparent',
    'mb-10',
    'border-1',
    'rounded',
    styles.input_container,
    formGroupClassName,
  ];

  if (readOnly) {
    groupClasses.push('border-info');
  }

  return (
    <Form.Group className={classNames(groupClasses)} controlId={controlId}>
      {type === 'search' && (
        <button
          className={classNames(
            'p-0 border-0 bg-transparent',
            styles.inputIcon
          )}
          type="button"
          onClick={onIconClick}
        >
          <FontAwesomeIcon icon={faSearch} />
        </button>
      )}
      <div
        className={classNames('position-relative', styles.input_subContainer)}
      >
        {formErrorMessage}
        <Form.Control
          aria-describedby={
            isInvalid ? invalidAriaDescribedBy : validAriaDescribedBy
          }
          autoComplete={autoComplete}
          // this only disables it here, when this input is re-used..
          // the same msg will bubble up to be decided on case by case
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          className={formControlClass}
          disabled={
            disabled &&
            !isInvalid &&
            validations.every((fn) => fn(defaultValue))
          }
          isInvalid={isInvalid || parentInvalid}
          max={max}
          maxLength={maxLength}
          min={min}
          minLength={minLength}
          name={controlId}
          required={required}
          type={type}
          pattern={pattern}
          placeholder={placeholder}
          readOnly={readOnly}
          value={inputValue}
          onBlur={onBlur}
          onChange={onChange}
          {...rest}
        />
        <Form.Label
          className={classNames(
            'fs-8',
            'fw-400',
            'lh-1',
            'mb-0',
            'px-4',
            {
              [styles.filled]: (inputValue ?? '') !== '',
            },
            labelClassName,
            {
              'text-info': readOnly,
            }
          )}
        >
          {label}
        </Form.Label>
      </div>
      {errorMessage && isInvalid && (
        <Form.Control.Feedback
          className={
            feedbackStyles ??
            `fs-6 position-absolute w-auto ${styles.input_error}`
          }
          id={`${controlId}-error`}
          role="alert"
          type="invalid"
        >
          {errorMessage}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}
