import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Button } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import AccountDrawer from 'components/AccountDrawer';
import AddToBagListener from 'components/AddToBagListener';
import FavoriteProductButton from 'components/FavoriteProductButton';
import HeaderBrand from 'components/HeaderBrand';
import HeaderMenuSection from 'components/HeaderMenuSection';
import Link from 'components/Link';
import Loyalty from 'components/Loyalty';
import MenuBar from 'components/MenuBar';
import MenuDrawer from 'components/MenuDrawer';
import MessageBar from 'components/MessageBar';
import NotificationModal from 'components/NotificationModal';
import SearchBar from 'components/SearchBar';
import SearchIcon from 'components/SearchIcon';
import ShoppingBagIcon from 'components/ShoppingBagIcon';
import SideDrawer from 'components/SideDrawer';
import StoreAndMenu from 'components/StoreAndMenu';
import useIsSmallViewport from 'hooks/useIsSmallViewport';
import { initializeFavoriteProducts } from 'stores/actions/favorite-products';
import { setPromoCode } from 'stores/actions/promo-code';
import {
  getAccountVersionDetails,
  setUserInfoFromLocalStorage,
} from 'stores/actions/user';
import { DRAWER_ACCOUNT } from 'stores/reducers/drawers';
import { showLoyaltyModal } from 'stores/reducers/loyalty-modal';
import setIdleInterval from 'utils/set-idle-interval';
import styles from './header.module.scss';
import menuButtonsStyle from '../HeaderMenuSection/HeaderMenuSection.module.scss';

const ModalSearchBar = dynamic(() => import('components/ModalSearchBar'), {
  ssr: false,
});

const handleMenuButtonsStyling = () => {
  const menuButtons = document.querySelectorAll('#header_menu_button');
  // eslint-disable-next-line unicorn/no-array-for-each
  menuButtons.forEach((mb) => {
    mb.classList.remove(menuButtonsStyle.activeMenuItem);
  });
};

const SIGN_IN_NOTIFICATION_ID = 'sign_in_notification';

function Header({
  changePasswordContent,
  confirmationCodeContent,
  createAnAccountLabel,
  createAccountContent,
  desktopMenuSections,
  filterMenuOptions,
  forgotPasswordContent,
  loginContent,
  loyaltyLabel,
  loyaltyUrl,
  menuBarItemsSections,
  menuSections: mobileMenuSections,
  mobileBottomNavLogoUrl,
  modalNotifications,
  shoppingBagIconUrl,
  storeMessages,
  storeSelectionContent,
  unifiedLogoDescription,
  unifiedLogoUrl,
}) {
  const [collapsed, setCollapsed] = useState(true);
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [showOnlyAlertMessages, setShowOnlyAlertMessages] = useState(false);
  const [showMenuBar, setShowMenuBar] = useState(true);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerContent, setDrawerContent] = useState([]);

  const isSmallViewport = useIsSmallViewport();
  const { isInitialState, isLoggedIn } = useSelector((state) => state.user);
  const userInfo = useSelector((state) => state.user.userInfo);
  const searchBarEnabled = useSelector(
    (state) => state.featureFlags.search_bar
  );
  const authV2ForceUpdate = useSelector(
    (state) => state.featureFlags.auth_v2_force_update
  );
  const selectedStoreFromState = useSelector((state) => state.storeSelection);

  const { currentDrawer } = useSelector((state) => state.drawers);

  const { firstName } = userInfo;

  const dispatch = useDispatch();
  const router = useRouter();

  const handleShowDrawer = (content) => {
    if (content) {
      // same behavior as react-bootstrap/modal
      const body = document.querySelector('body');
      body.setAttribute(
        'style',
        'overflow:hidden; padding-right: ' +
          (window.innerWidth - body.clientWidth) +
          'px'
      );
      setShowDrawer(true);
      setDrawerContent(content);
    }
  };

  const handleHideDrawer = () => {
    const body = document.querySelector('body');

    body.removeAttribute('style');
    handleMenuButtonsStyling();
    setShowDrawer(false);

    setDrawerContent([]);
  };

  const toggleCollapsed = useCallback(() => {
    setCollapsed((val) => !val);
  }, []);

  const toggleMobileMenu = useCallback(() => {
    setShowMobileMenu((val) => !val);
  }, []);

  const openFavoriteProducts = useCallback(() => {
    closeAll();
    router.push('/account?section=favoriteProducts');
  }, [router]);

  const openShoppingBag = useCallback(() => {
    closeAll();
    router.push('/bag');
  }, [router]);

  const openSearchBar = useCallback(
    (input) => {
      setSearchBarOpen(true);
      setSearchInput(typeof input === 'string' ? input : '');
    },

    [setSearchBarOpen]
  );
  const closeSearchBar = useCallback(() => {
    setSearchBarOpen(false);
  }, [setSearchBarOpen]);

  const closeAll = () => {
    setCollapsed(true);
    setSearchBarOpen(false);
  };

  const handleSameUrlClick = useCallback(
    (url) => {
      if (router.asPath === url) {
        closeAll();
      }
    },
    [router.asPath]
  );

  useEffect(() => {
    dispatch(setUserInfoFromLocalStorage(authV2ForceUpdate));
    dispatch(initializeFavoriteProducts());
  }, [dispatch]);

  // once a day hit to keep tokens refreshed
  useEffect(() => {
    const cleanup = setIdleInterval(() => {
      getAccountVersionDetails();
    }, 86_400_000);
    return cleanup;
  }, []);

  useEffect(() => {
    if (router.route === '/') {
      setShowOnlyAlertMessages(true);
    } else {
      setShowOnlyAlertMessages(false);
    }

    if (
      router.route === '/bag' ||
      router.route === '/account' ||
      router.route.includes('/special')
    ) {
      setShowMenuBar(false);
    } else {
      setShowMenuBar(true);
    }

    // (almost) site-wide refresh every 12hours
    const cleanup = setIdleInterval(() => {
      router.replace({ pathname: router.asPath, query: router.query });
    }, 43_200_000);
    closeAll();

    const { promo, showModal } = router.query;
    if (promo) {
      const expirationDate = dayjs().add(1, 'day').format('YYYY-MM-DD');
      dispatch(setPromoCode({ code: promo, expirationDate }));
    }

    if (
      (router.asPath?.includes('loyalty') ||
        router.asPath?.includes('account')) &&
      showModal === 'true'
    ) {
      dispatch(showLoyaltyModal());
    }

    return cleanup;
  }, [router.asPath, router.route]);

  useEffect(() => {
    // if were mobile and the nav bar is uncollapsed, turn off scrolling on the underlying content
    if (collapsed || !showMobileMenu) {
      document.querySelector('body').classList.remove('mobile-nav-no-scroll');
    } else {
      document.querySelector('body').classList.add('mobile-nav-no-scroll');
    }

    // if the menu is open and we switch to desktop view then ensure that the menu collapsed state is reset
    // we need this for the sticky behavior because there is no way in css for the sticky container to know
    // the collapsed state of the navbar and turn on and off appropriately on a screen resize
    if (!isSmallViewport && !collapsed) {
      toggleCollapsed();
    }
    if (!isSmallViewport && showMobileMenu) {
      toggleMobileMenu();
    }
  }, [
    collapsed,
    isSmallViewport,
    showMobileMenu,
    toggleCollapsed,
    toggleMobileMenu,
  ]);

  useEffect(() => {
    if (isSmallViewport) {
      handleHideDrawer();
    }
  }, [isSmallViewport]);

  return (
    <>
      <header className={classNames(styles.sticky_transform, 'bg-white')}>
        <div id="header_container">
          <div className="position-absolute">
            <div
              id={SIGN_IN_NOTIFICATION_ID}
              className={styles.notification_wrapper}
            />
          </div>
          <div
            className={classNames(styles.navbar_container, {
              [styles.scroll]: !collapsed,
            })}
          >
            <Nav
              aria-label="header navigation"
              role="navigation"
              className={classNames(
                'bg-white pb-xl-6 pt-0 pt-xl-4',
                styles.navbar_content
              )}
            >
              <Container fluid className="pe-3 ps-0 px-xl-0 pt-xl-3">
                <div className="position-relative" id="basic-navbar-nav">
                  <div className="d-flex flex-row p-0 ms-0 bg-white">
                    <HeaderBrand
                      unifiedLogoDescription={unifiedLogoDescription}
                      unifiedLogoUrl={unifiedLogoUrl}
                    />
                    <div className="d-none d-xl-block d-xl-flex pt-3 ps-4 ps-xxl-13">
                      <HeaderMenuSection
                        handleHeaderClick={handleHideDrawer}
                        handleMenuClick={handleShowDrawer}
                        menuSections={desktopMenuSections}
                      />
                    </div>
                    <div className="w-100">
                      <Row className="d-none d-xl-flex flex-nowrap justify-content-end text-end-xl text-uppercase ps-xl-0 pe-xl-10">
                        <StoreAndMenu
                          filterMenuOptions={filterMenuOptions}
                          storeSelectionContent={storeSelectionContent}
                        />
                        <Loyalty
                          loyaltyLabel={loyaltyLabel}
                          loyaltyUrl={loyaltyUrl}
                        />
                        <Col xl="auto" className="ps-12">
                          <Link
                            href="/account"
                            data-value="lume-home-page-my-account-link"
                            aria-label="My Account"
                            className={classNames(
                              'border-0 bg-transparent fs-8 p-0 text-primary font-geo fw-xl-600 text-uppercase',
                              styles.link
                            )}
                            onClick={() => handleSameUrlClick('/account')}
                          >
                            {isLoggedIn && firstName
                              ? `Hello, ${firstName}`
                              : 'Sign In'}
                          </Link>
                        </Col>
                      </Row>
                      <Row className="justify-content-end pt-xl-6 pb-xl-3">
                        <div className="d-flex justify-content-end align-items-center">
                          <div className="d-xl-none pt-6">
                            <SearchIcon openSearchModal={openSearchBar} />
                          </div>
                          <div className="d-none d-xl-block px-xl-3 ">
                            <SearchBar openSearchModal={openSearchBar} />
                          </div>
                          <div className="pt-2 pt-xl-0 px-1 px-xl-2">
                            <FavoriteProductButton
                              className={styles.heart_icon}
                              handleButtonClick={openFavoriteProducts}
                              label="Go to favorite products"
                            />
                          </div>
                          <div className="px-xl-7 pt-xl-3">
                            <ShoppingBagIcon
                              openShoppingBag={openShoppingBag}
                              shoppingBagIconUrl={shoppingBagIconUrl}
                            />
                          </div>
                          <Button
                            aria-label="Menu"
                            variant="none"
                            type="button"
                            className="d-xl-none pt-8 px-2"
                            onClick={toggleMobileMenu}
                          >
                            <span
                              className={classNames(
                                'd-inline-block p-0 text-primary',
                                styles.menu_open
                              )}
                            >
                              <FontAwesomeIcon icon={faBars} />
                            </span>
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </div>
                </div>
              </Container>
            </Nav>
            {searchBarEnabled && (
              <ModalSearchBar
                closeAllMenus={handleSameUrlClick}
                closeModal={closeSearchBar}
                showModal={searchBarOpen}
                searchInput={searchInput}
              />
            )}
          </div>
        </div>
        <AddToBagListener />
        <MenuDrawer
          firstName={firstName}
          handleClose={toggleMobileMenu}
          isLoggedIn={isLoggedIn}
          menuSections={mobileMenuSections}
          mobileBottomNavLogoUrl={mobileBottomNavLogoUrl}
          selectedStoreFromState={selectedStoreFromState}
          shoppingBagIconUrl={shoppingBagIconUrl}
          showDrawer={showMobileMenu}
        />
        <SideDrawer
          menuSections={drawerContent}
          handleBackdropClick={handleHideDrawer}
          showDrawer={showDrawer}
        />
        <AccountDrawer
          changePasswordContent={changePasswordContent}
          confirmationCodeContent={confirmationCodeContent}
          createAccountContent={createAccountContent}
          createAnAccountLabel={createAnAccountLabel}
          forgotPasswordContent={forgotPasswordContent}
          loginContent={loginContent}
          showDrawer={currentDrawer === DRAWER_ACCOUNT}
        />
      </header>
      <MessageBar
        showOnlyAlertMessages={showOnlyAlertMessages}
        storeMessages={storeMessages}
      />
      {showMenuBar && <MenuBar menuBarItemsSections={menuBarItemsSections} />}
      {!isInitialState && !isLoggedIn && router?.route !== '/account' && (
        <NotificationModal
          ariaLabel="Sign In To Account"
          modalContent={modalNotifications?.sign_in}
          notificationId={SIGN_IN_NOTIFICATION_ID}
        />
      )}
    </>
  );
}

export default Header;
