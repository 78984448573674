import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styles from './LumeTooltip.module.scss';

export const LumeTooltip = ({
  children,
  iconColorClass = 'text-primary',
  tooltipPlacement = 'left',
}) => {
  const ToolTipIcon = (
    <Tooltip className={styles.tooltip} id="lume-tooltip">
      {children}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      delay={{ show: 100, hide: 100 }}
      overlay={ToolTipIcon}
      placement={tooltipPlacement}
    >
      <span className="d-flex align-items-center">
        <FontAwesomeIcon
          icon={faCircleInfo}
          className={iconColorClass}
          style={{ width: 16, height: 16 }}
        />
      </span>
    </OverlayTrigger>
  );
};

export default LumeTooltip;
