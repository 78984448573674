import dayjs from 'dayjs';
import isBrowser from './is-browser';

export default function generateEncodedLoyaltyConsent({
  email,
  birthdate,
  firstName,
  lastName,
  phoneNumber,
}) {
  if (
    !isBrowser ||
    email === '' ||
    birthdate === '' ||
    firstName === '' ||
    lastName === '' ||
    phoneNumber === ''
  ) {
    return;
  }

  const today = dayjs();
  const formattedDob = dayjs(birthdate).format('YYYY-MM-DD');
  const consentString = `${firstName} ${lastName} opted into Loyalty SMS on ${today}.`;
  const userDataString = `User Data: Email: ${email} Phone: ${phoneNumber} DOB: ${formattedDob}`;

  const canvas = document.createElement('canvas');
  canvas.width = 750;
  canvas.height = 100;

  const context = canvas.getContext('2d');
  context.fillStyle = 'white';
  context.fillText(consentString, 50, 50);
  context.fillText(userDataString, 50, 60);

  const url = canvas.toDataURL('image/jpeg');

  const strippedUrl = url.replace('data:image/jpeg;base64,', '');

  return strippedUrl;
}
