const DATE_FILLER_STRING = 'MMDDYYYY';
const DATE_PLACEHOLDER_STRING = 'MM/DD/YYYY';
const CURSOR_OFFSETS = [
  { insertOffset: 0, deleteOffset: 0 },
  { insertOffset: 0, deleteOffset: 0 },
  { insertOffset: 1, deleteOffset: 0 },
  { insertOffset: 1, deleteOffset: 1 },
  { insertOffset: 2, deleteOffset: 1 },
  { insertOffset: 2, deleteOffset: 2 },
  { insertOffset: 2, deleteOffset: 2 },
  { insertOffset: 2, deleteOffset: 2 },
  { insertOffset: 2, deleteOffset: 2 },
];
const formatDate = (value, e) => {
  const sanitized = value.replace(/\D/g, '');
  const { length } = sanitized;

  if (length === 0) {
    return { formatted: DATE_PLACEHOLDER_STRING, cursorPosition: 0 };
  }

  const filled = sanitized + DATE_FILLER_STRING.slice(length);

  const dateArray = [...filled];
  dateArray.splice(2, 0, '/');
  dateArray.splice(5, 0, '/');
  const formatted = dateArray.slice(0, 10).join('');

  const isDeleteEvent = e?.nativeEvent.inputType.includes('delete');

  const cursorOffset = isDeleteEvent
    ? CURSOR_OFFSETS[length]?.deleteOffset ?? 0
    : CURSOR_OFFSETS[length]?.insertOffset ?? 1;

  const cursorPosition = length + cursorOffset;

  return { formatted, cursorPosition };
};

export default formatDate;
