import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import constructNavigationMenu from 'utils/construct-menu';
import styles from './SideDrawer.module.scss';

const MenuListItem = ({ item, handleMenuItemClick }) => {
  const menuItemRef = useRef(null);
  return (
    <li className="py-4">
      <button
        ref={menuItemRef}
        className={classNames(
          'bg-transparent d-flex flex-row font-geo fs-14 justify-content-between text-primary w-100',
          styles.menu_item_button
        )}
        id="menu_item_button"
        title="Menu Item"
        type="button"
        onClick={handleMenuItemClick(item, menuItemRef)}
      >
        <span className={styles.menu_text}>{item?.value?.label}</span>
        {item?.children?.length > 0 && (
          <span>
            <FontAwesomeIcon
              className={styles.variable_size_icon}
              icon={faChevronRight}
            />
          </span>
        )}
      </button>
    </li>
  );
};

export default function SideDrawer({
  menuSections,
  handleBackdropClick,
  showDrawer,
}) {
  const router = useRouter();
  const [currMenu, setCurrMenu] = useState([]);
  const [currSubMenu, setCurrSubMenu] = useState([]);

  useEffect(() => {
    if (menuSections) {
      const sections = constructNavigationMenu({ menuSections });
      setCurrMenu(sections);
      setCurrSubMenu([]);
    }
  }, [menuSections]);

  useEffect(() => {
    document
      ?.querySelector('#backdrop')
      ?.addEventListener('click', handleBackdropClick);

    return () => {
      if (document.querySelector('#backdrop')) {
        document
          .querySelector('#backdrop')
          .removeEventListener('click', handleBackdropClick);
      }
    };
  }, [handleBackdropClick, showDrawer]);

  const handleMenuItemClick = (child, childRef) => async () => {
    const menuButtons = document.querySelectorAll('#menu_item_button');
    if (menuButtons) {
      // eslint-disable-next-line unicorn/no-array-for-each
      menuButtons.forEach((mb) => {
        mb.classList.remove(styles.active_button);
      });
    }

    childRef?.current?.classList.add(styles.active_button);
    if (child?.value?.href) {
      await router.push(child.value.href);
      handleBackdropClick();
      setCurrSubMenu([]);
      return;
    }

    setCurrSubMenu(child);
  };

  return showDrawer && menuSections?.length ? (
    <div
      className={classNames('w-100 h-100', styles.drawer_container, {
        [styles.drawer_container_open]: showDrawer,
      })}
    >
      <div
        id="backdrop"
        className={classNames('w-100 h-100', styles.drawer_backdrop)}
      />
      <div className="d-flex flex-row h-100">
        <div
          className={classNames(
            'h-100 ps-10 p-4 d-flex flex-row',
            styles.drawer
          )}
        >
          <ul
            className={classNames('list-unstyled pb-12', {
              'pt-10': currMenu?.parent,
            })}
          >
            {currMenu?.children?.map((child) => {
              return (
                <MenuListItem
                  key={`key_${child?.value?.label ?? 'menu_item'}`}
                  item={child}
                  handleMenuItemClick={handleMenuItemClick}
                />
              );
            })}
          </ul>
        </div>
        <div
          className={classNames('h-100 ps-10 p-4', styles.sub_menu_drawer, {
            [styles.sub_menu_drawer_open]: currSubMenu?.length === 0,
          })}
        >
          <ul
            className={classNames('list-unstyled pb-12', {
              'pt-10': currMenu?.parent,
            })}
          >
            {currSubMenu?.children?.map((child) => {
              return (
                <MenuListItem
                  key={`key_${child?.value?.label ?? 'menu_item'}`}
                  item={child}
                  handleMenuItemClick={handleMenuItemClick}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
}
