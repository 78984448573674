import { createSlice } from '@reduxjs/toolkit';
import { extraReducers } from 'stores/default-extra-reducers';

export const DRAWER_NONE = 'none';
export const DRAWER_ACCOUNT = 'account';
const drawerSet = new Set([DRAWER_ACCOUNT, DRAWER_NONE]);

const initialState = {
  currentDrawer: DRAWER_NONE,
};

const drawersSlice = createSlice({
  name: 'drawers',
  initialState,
  reducers: {
    setCurrentDrawer(state, action) {
      const currentDrawer = drawerSet.has(action.payload)
        ? action.payload
        : DRAWER_NONE;
      return { ...state, currentDrawer };
    },
  },
  extraReducers,
});

export const { setCurrentDrawer } = drawersSlice.actions;
export default drawersSlice;
