import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import useIsMounted from 'hooks/useIsMounted';
import { DRAWER_ACCOUNT, setCurrentDrawer } from 'stores/reducers/drawers';
import {
  MODAL_SELECT_STORE,
  setCurrentModal,
} from 'stores/reducers/store-selection-modal';
import styles from './NotificationModal.module.scss';

const SIGN_IN = 'SIGN IN';
const STORE_SELECTOR = 'STORE SELECTOR';

export default function NotificationModal({
  ariaLabel,
  modalContent,
  notificationId,
}) {
  const dispatch = useDispatch();
  const router = useRouter();
  const mounted = useIsMounted();
  const [show, setShow] = useState(true);

  const handleModalClose = () => {
    setShow(false);
  };

  const { content, ctaAction, ctaHref, ctaText } = modalContent ?? {};

  const handleCtaClick = () => {
    switch (ctaAction) {
      case STORE_SELECTOR: {
        dispatch(setCurrentModal(MODAL_SELECT_STORE));
        handleModalClose();
        break;
      }
      case SIGN_IN: {
        dispatch(setCurrentDrawer(DRAWER_ACCOUNT));
        handleModalClose();
        break;
      }
      default: {
        if (ctaHref) {
          router.push(ctaHref);
        }
        handleModalClose();
      }
    }
  };

  const ModalChild = ({ ariaLabel }) => (
    <div aria-label={ariaLabel} className={styles.modal_container}>
      <div
        className={classNames(
          'd-flex justify-content-between',
          styles.modal_wrapper
        )}
      >
        <div>
          {content && (
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: content }}
              className={classNames(
                'fs-7 text-primary me-5',
                styles.modal_content
              )}
            />
          )}
          {ctaAction && (
            <Button
              className={classNames('p-0 fs-7', styles.modal_cta)}
              variant="none"
              type="button"
              onClick={handleCtaClick}
            >
              {ctaText}
            </Button>
          )}
        </div>
        <div className={styles.modal_arrow} />
        <Button
          className={classNames('p-0', styles.modal_close_icon_container)}
          variant="none"
          type="button"
          onClick={handleModalClose}
        >
          <span
            className={classNames(
              'd-inline-block text-primary',
              styles.modal_close_icon
            )}
          >
            <FontAwesomeIcon icon={faXmark} />
          </span>
        </Button>
      </div>
    </div>
  );

  return mounted && show && modalContent
    ? createPortal(
        <ModalChild ariaLabel={ariaLabel} />,
        document.querySelector(`#${notificationId}`)
      )
    : null;
}
