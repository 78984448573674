import { createRef, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormError from 'components/FormError';
import FormInput from 'components/FormInput';
import logger from 'config/logger';
import { RequestInstance } from 'request';
import { sendPageView } from 'utils/metrics';
import validatePassword from 'utils/validate-password';
import styles from './ForgotPassword.module.scss';

const passwordValidations = [validatePassword];
const backToLoginText = 'Remembered your password?';

export default function ForgotPassword({
  changePasswordContent = {},
  confirmationCodeContent = {},
  createAccountContent = {},
  forgotPasswordContent = {},
  focusOnModal,
  handleOpenLogin,
}) {
  const router = useRouter();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const [password, setPassword] = useState('');
  const [storedEmail, setStoredEmail] = useState('');
  const [attemptingChange, setAttemptingChange] = useState(false);
  const [attemptingForgot, setAttemptingForgot] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState(null);
  const [forgotPasswordError, setForgotPasswordError] = useState(null);
  const [oobCode, setOobCode] = useState('');
  const confirmPasswordRef = useRef(null);

  // clean url of oobCode and other stuff from reset email
  useEffect(() => {
    if (router.query.mode === 'resetPassword') {
      // handle special email chars
      const { pathname, query } = router;

      setOobCode(query.oobCode ?? '');
      setStoredEmail(query.email?.replace(' ', '+') ?? '');
      setShowChangePassword(true);
      router.push({ pathname }, undefined, { shallow: true });
    }
  }, []);

  useEffect(() => {
    sendPageView(showChangePassword ? 'Change Password' : 'Forgot Password');
  }, [showChangePassword]);

  const formRef = createRef();
  const confirmPasswordValidations = [(value) => value === password];

  const validateForm = () => {
    setValidated(formRef.current.checkValidity());
  };

  const validateChangePasswordForm = () => {
    try {
      const {
        confirm_new_password: { value: confirmNewPassword },
        new_password: { value: newPassword },
      } = formRef.current.elements;
      const htmlValidated = formRef.current.checkValidity();
      const isPasswordValid = passwordValidations.every((fn) =>
        fn(newPassword)
      );
      const isConfirmPasswordValid = confirmPasswordValidations.every((fn) =>
        fn(confirmNewPassword)
      );

      setValidated(htmlValidated && isPasswordValid && isConfirmPasswordValid);
    } catch (err) {
      logger.error(err, 'Error validating form');
      setValidated(false);
    }
  };

  const {
    confirm_password_invalid: confirmPasswordInvalid,
    email_address_invalid: emailAddressInvalid,
    login_label: loginLabel,
    password_instructional_text: passwordInstructionalText,
    password_invalid: passwordInvalid,
  } = createAccountContent;

  const {
    email_address_input: emailAddressInput = {},
    submit_button: submitButton,
    title: forgotPasswordTitle,
  } = forgotPasswordContent;

  const {
    match_new_password_input: matchNewPasswordInput = {},
    new_password_input: newPasswordInput = {},
    save_password_button: savePasswordButtonLabel,
    title: changePasswordTitle,
  } = changePasswordContent;

  const {
    confirmation_code_input: confirmationCodeInput = {},
    email_warning: emailWarning,
    title: confirmationCodeTitle,
  } = confirmationCodeContent;

  const { label: emailAddressLabel, placeholder: emailAddressPlaceholder } =
    emailAddressInput;

  const {
    label: matchNewPasswordLabel,
    placeholder: matchNewPasswordPlaceholder,
  } = matchNewPasswordInput;

  const { label: newPasswordLabel, placeholder: newPasswordPlaceholder } =
    newPasswordInput;

  const {
    label: confirmationCodeLabel,
    placeholder: confirmationCodePlaceholder,
  } = confirmationCodeInput;

  const attemptForgotPassword = async ({ email }) => {
    try {
      setAttemptingForgot(true);
      const { success } = await RequestInstance.post(
        'api/account/forgot-password',
        {
          json: { email },
        }
      );

      if (success) {
        setStoredEmail(email);
        setForgotPasswordError(null);
        setShowChangePassword(true);
        setValidated(false);
        if (focusOnModal) {
          focusOnModal();
        }
      }
    } catch (err) {
      if (err?.message) {
        setForgotPasswordError(err?.message);
      }

      logger.error(err, 'Error calling forgot password api');
    } finally {
      setAttemptingForgot(false);
    }
  };

  const onForgotPasswordSubmit = (event) => {
    event.stopPropagation();
    event.preventDefault();
    try {
      const {
        forgot_password_email_address: { value: email },
      } = event.target.elements;
      attemptForgotPassword({ email });
    } catch (err) {
      logger.error(err, 'Error in forgot password submit');
    }
    return false;
  };

  const attemptChangePassword = async (data) => {
    try {
      setAttemptingChange(true);
      const { success } = await RequestInstance.post(
        'api/account/change-password',
        {
          json: data,
        }
      );

      if (success) {
        setOobCode('');
        setChangePasswordError('');
        handleOpenLogin();
      }
    } catch (err) {
      if (err?.message) {
        setChangePasswordError(err?.message);
      }

      logger.error(err, 'Error calling change password api');
    } finally {
      setAttemptingChange(false);
    }
  };

  const onChangePasswordSubmit = (event) => {
    event.stopPropagation();
    event.preventDefault();

    try {
      const {
        change_password_email: { value: email },
        change_password_verification_code: { value: confirmationCode },
        confirm_new_password: { value: confirmPassword },
        new_password: { value: password },
      } = event.target.elements;

      /* eslint-disable camelcase */
      attemptChangePassword({
        confirm_password: confirmPassword,
        confirmation_code: confirmationCode,
        email,
        password,
      });
      /* eslint-enable camelcase */
    } catch (err) {
      logger.error(err, 'Error submitting change password form');
    }
  };

  const resetConfirmPasswordField = () => {
    const {
      confirm_new_password: { value: confirmPassword },
    } = formRef.current.elements;

    if (confirmPassword) {
      confirmPasswordRef.current.resetFormInput();
    }
  };

  return (
    <>
      <div
        id="account-forget-password-title"
        className="font-freight fs-16 lh-1 mb-8 mt-15 text-primary text-center"
      >
        {showChangePassword
          ? oobCode
            ? changePasswordTitle
            : 'Request Received!'
          : forgotPasswordTitle}
      </div>
      <div className="font-acumin fs-7 mb-8">
        <span>{backToLoginText}</span>
        <button
          className={`bg-transparent border-0 fw-bold text-decoration-underline text-primary ${styles.link}`}
          type="button"
          onClick={handleOpenLogin}
        >
          {loginLabel}
        </button>
      </div>
      <div className="mt-8 mb-15 w-100">
        {showChangePassword ? (
          oobCode ? (
            <Form
              ref={formRef}
              aria-describedby="change_password_email_warning"
              id="change_password_form"
              onSubmit={onChangePasswordSubmit}
            >
              <fieldset className={classNames({ 'd-none': oobCode })}>
                <legend className="font-freight fs-10 fw-bold text-primary">
                  {confirmationCodeTitle}
                </legend>
                <FormInput
                  required
                  controlId="change_password_verification_code"
                  defaultValue={oobCode}
                  label={confirmationCodeLabel}
                  placeholder={confirmationCodePlaceholder}
                  type="text"
                  validateForm={validateChangePasswordForm}
                  validationMessage="Please enter a verification code"
                />
              </fieldset>
              <FormInput
                required
                aria-describedby="password-instructions"
                controlId="new_password"
                label={newPasswordLabel}
                placeholder={newPasswordPlaceholder}
                type="password"
                validateForm={validateChangePasswordForm}
                validationMessage={passwordInvalid}
                validations={passwordValidations}
                onChange={(e) => {
                  setPassword(e.target.value);
                  resetConfirmPasswordField();
                }}
              />
              <p className="fs-6 text-muted my-8" id="password-instructions">
                {passwordInstructionalText}
              </p>
              <FormInput
                required
                controlId="confirm_new_password"
                formInputRef={confirmPasswordRef}
                label={matchNewPasswordLabel}
                placeholder={matchNewPasswordPlaceholder}
                type="password"
                validateForm={validateChangePasswordForm}
                validationMessage={confirmPasswordInvalid}
                validations={confirmPasswordValidations}
              />
              <input
                type="hidden"
                name="change_password_email"
                id="change_password_email"
                value={storedEmail}
              />
              <p
                id="change_password_email_warning"
                className={classNames('fs-7 mt-8', { 'd-none': oobCode })}
              >
                {emailWarning}
              </p>
              <Button
                className={`fs-9 mt-15 ${styles.forgot_password_button}`}
                disabled={!validated || attemptingChange}
                id="change_password_submit_button"
                type="submit"
                variant="secondary"
              >
                {savePasswordButtonLabel}
              </Button>
              <FormError message={changePasswordError} />
            </Form>
          ) : (
            <p id="change_password_email_warning" className="fs-7 mt-8">
              {emailWarning}
            </p>
          )
        ) : (
          <Form
            ref={formRef}
            id="forgot_password_form"
            onSubmit={onForgotPasswordSubmit}
          >
            <FormInput
              required
              controlId="forgot_password_email_address"
              label={emailAddressLabel}
              pattern="^\S+@\S+\.\S\S+$"
              placeholder={emailAddressPlaceholder}
              type="email"
              validateForm={validateForm}
              validationMessage={emailAddressInvalid}
            />
            <Button
              className={`fs-9 w-100 mt-9 ${styles.forgot_password_button}`}
              disabled={!validated || attemptingForgot}
              id="forgot_password_submit_button"
              type="submit"
              variant="secondary"
            >
              {submitButton}
            </Button>
            <FormError message={forgotPasswordError} />
          </Form>
        )}
      </div>
    </>
  );
}
