import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import thunk from 'redux-thunk';
import ageGateSlice from './reducers/age-gate';
import cartSlice from './reducers/cart';
import cartWarningModalSlice from './reducers/cart-warning-modal';
import drawersSlice from './reducers/drawers';
import favoriteProductsSlice from './reducers/favorite-products';
import featureFlagsSlice from './reducers/feature-flags';
import loyaltyModalSlice from './reducers/loyalty-modal';
import modalOpenSlice from './reducers/modal-open';
import orderSlice from './reducers/order';
import productFetchStatusSlice from './reducers/product-fetch-status';
import productPromoSlice from './reducers/product-promo';
import promoSlice from './reducers/promo-code';
import selectedStoreInformationSlice from './reducers/selected-store-information';
import menuSelectionSlice from './reducers/store-menu';
import storePromoCodesSlice from './reducers/store-promo-codes';
import storeSelectionSlice from './reducers/store-selection';
import storeSelectionModalSlice from './reducers/store-selection-modal';
import upsellSlice from './reducers/upsell-modal';
import userSlice from './reducers/user';

const makeStore = () =>
  configureStore({
    reducer: {
      [ageGateSlice.name]: ageGateSlice.reducer,
      [cartSlice.name]: cartSlice.reducer,
      [cartWarningModalSlice.name]: cartWarningModalSlice.reducer,
      [drawersSlice.name]: drawersSlice.reducer,
      [favoriteProductsSlice.name]: favoriteProductsSlice.reducer,
      [featureFlagsSlice.name]: featureFlagsSlice.reducer,
      [loyaltyModalSlice.name]: loyaltyModalSlice.reducer,
      [menuSelectionSlice.name]: menuSelectionSlice.reducer,
      [modalOpenSlice.name]: modalOpenSlice.reducer,
      [orderSlice.name]: orderSlice.reducer,
      [productFetchStatusSlice.name]: productFetchStatusSlice.reducer,
      [productPromoSlice.name]: productPromoSlice.reducer,
      [promoSlice.name]: promoSlice.reducer,
      [selectedStoreInformationSlice.name]:
        selectedStoreInformationSlice.reducer,
      [storePromoCodesSlice.name]: storePromoCodesSlice.reducer,
      [storeSelectionSlice.name]: storeSelectionSlice.reducer,
      [storeSelectionModalSlice.name]: storeSelectionModalSlice.reducer,
      [upsellSlice.name]: upsellSlice.reducer,
      [userSlice.name]: userSlice.reducer,
    },
    middleware: [thunk],
  });

export const wrapper = createWrapper(makeStore);
