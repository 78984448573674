// account
export const ACCOUNT_COMMUNICATION_PREFERENCES =
  'accountCommunicationPreferences';
export const ACCOUNT_PERSONAL_DETAILS = 'accountPersonalDetails';
export const ACCOUNT_SHOP_PREFERENCES = 'accountShopPreferences';
export const CREATE_ACCOUNT = 'createAccount';
export const FAVORITE_PRODUCTS = 'favoriteProducts';
export const FORGOT_PASSWORD = 'forgotPassword';
export const LOGIN = 'login';
export const MY_ACCOUNT_HOME = 'myAccountHome';
export const PASSWORD_RESET_MODE = 'resetPassword';
