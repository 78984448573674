import { createRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormError from 'components/FormError';
import FormInput from 'components/FormInput';
import logger from 'config/logger';
import { RequestInstance } from 'request';
import { initializeFavoriteProducts } from 'stores/actions/favorite-products';
import { setUserInfo } from 'stores/actions/user';
import { sendEvent, sendPageView } from 'utils/metrics';
import styles from './Login.module.scss';

export default function Login({
  handleOpenCreateAccount,
  handleOpenForgotPassword,
  handleOpenMyAccount,
  loginContent = {},
  showEmailVerifiedSuccess,
  verifiedEmail,
  onSuccess,
}) {
  const [attemptingLogin, setAttemptingLogin] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formError, setFormError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    sendPageView('Login Modal');
  }, []);

  const attemptLogin = async ({ username, password, remember }) => {
    try {
      setAttemptingLogin(true);
      const { message, userInfo } = await RequestInstance.post(
        'api/account/login',
        {
          json: { username, password, remember },
        }
      );

      if (message) {
        setFormError(message);
      }

      if (userInfo) {
        if (onSuccess) {
          onSuccess(userInfo);
        }
        dispatch(setUserInfo(userInfo));
        dispatch(initializeFavoriteProducts());

        if (handleOpenMyAccount) {
          handleOpenMyAccount();
        }
      }
    } catch (err) {
      if (err?.message) {
        setFormError(err?.message);
      }

      logger.error(err, 'Error with log in');
    } finally {
      setAttemptingLogin(false);
    }
  };

  const onSubmit = (event) => {
    event.stopPropagation();
    event.preventDefault();
    try {
      const {
        remember_login: { checked: remember },
        login_email_address: { value: username },
        login_password: { value: password },
      } = event.target.elements;
      attemptLogin({ username, password, remember });
      sendEvent('login', { method: 'header' });
    } catch (err) {
      logger.error(err, 'Error in login submit');
    }
    return false;
  };

  const formRef = createRef();

  const validateForm = () => {
    setValidated(formRef.current.checkValidity());
  };

  const {
    email_address_label: emailAddressLabel,
    email_address_placeholder: emailAddressPlaceholder,
    email_validation_message: emailValidationMessage,
    forgot_password_text: forgotPasswordText,
    keep_signed_in: keepSignedInText,
    login_button_text: loginButtonText,
    login_create_account: loginCreateAccountText,
    login_create_account_heading: loginCreateAccountHeading,
    login_heading: loginHeading,
    password_label: passwordLabel,
    password_placeholder: passwordPlaceholder,
    password_validation_message: passwordValidationMessage,
  } = loginContent;

  return (
    <>
      <div
        id="account-login-title"
        className="font-freight fs-16 lh-1 mb-2 mt-15 text-primary text-center"
      >
        <h1>{loginHeading}</h1>
      </div>
      <div className="mt-12 w-100">
        <Form ref={formRef} noValidate id="login_form" onSubmit={onSubmit}>
          <FormInput
            required
            className={`${styles.no_validation_icons}`}
            controlId="login_email_address"
            defaultValue={verifiedEmail}
            label={emailAddressLabel}
            pattern="^\S+@\S+\.\S\S+$"
            placeholder={emailAddressPlaceholder}
            type="email"
            validateForm={validateForm}
            validationMessage={emailValidationMessage}
          />
          {showEmailVerifiedSuccess && (
            <div className="mb-16">
              <span className={`d-inline-block me-8 ${styles.success_icon}`}>
                <FontAwesomeIcon icon={faCircleCheck} />
              </span>
              <span className="font-geo fs-8">Email Verified.</span>
            </div>
          )}
          <FormInput
            required
            className={`${styles.no_validation_icons}`}
            controlId="login_password"
            label={passwordLabel}
            placeholder={passwordPlaceholder}
            type="password"
            validateForm={validateForm}
            validationMessage={passwordValidationMessage}
          />

          <Form.Group
            className="position-relative mt-4 mb-3 d-flex justify-content-between"
            controlId="remember_login"
          >
            <Form.Check
              defaultChecked
              className={` ${styles.check_box}`}
              name="remember_login"
              type="checkbox"
              label={keepSignedInText}
            />
            <Button
              className={`bg-transparent fs-7 mt-0 text-decoration-underline ${styles.link}`}
              type="button"
              onClick={handleOpenForgotPassword}
            >
              {forgotPasswordText}
            </Button>
          </Form.Group>

          <Button
            className="fs-9 mt-15 w-100"
            disabled={!validated || attemptingLogin}
            id="login_submit_button"
            type="submit"
            variant="secondary"
          >
            {loginButtonText}
          </Button>
          <FormError message={formError} />
        </Form>
      </div>
      <div id="separator" className={`mt-20 mb-20 ${styles.separator}`} />
      <div
        id="account-login-create-title"
        className="font-freight fs-16 lh-1 text-primary text-center"
      >
        {loginCreateAccountHeading}
      </div>
      <div className="fs-7 mb-15">
        <Button
          className="fs-9 mt-10 w-100"
          type="submit"
          variant="secondary"
          aria-describedby="login-create-account-text"
          onClick={handleOpenCreateAccount}
        >
          {loginCreateAccountText}
        </Button>
      </div>
    </>
  );
}
