const formatPhoneNumber = (value, e) => {
  const stripped = value.replace(/\D/g, '');
  const noPlaceholders = value.replace(/_/g, '');
  const strippedLength = stripped.length;
  const sanitized =
    e?.nativeEvent.inputType.includes('delete') &&
    (/\d/.test(noPlaceholders.slice(-1)) ||
      noPlaceholders.slice(-2) === ')-') &&
    (strippedLength === 6 || strippedLength === 3)
      ? stripped.slice(0, -1)
      : stripped;
  const { length } = sanitized;
  const filled = sanitized.padEnd(10, '_');
  const phoneArray = [...filled];
  const cursorPosition =
    length < 3 ? length + 1 : length < 6 ? length + 3 : length + 4;
  phoneArray.splice(0, 0, '(');
  phoneArray.splice(4, 0, ') ');
  phoneArray.splice(8, 0, '-');
  const formatted = phoneArray.slice(0, 13).join('');
  return { formatted, cursorPosition };
};

export default formatPhoneNumber;
