export const LOWERCASE_REGEX = /[a-z]/;
export const NUMBER_REGEX = /\d/;
export const SYMBOL_REGEX = /[ !"#$%&'()*+,./:;<=>?@[\]^_`{|}~-]/;
export const UPPERCASE_REGEX = /[A-Z]/;

const hasLowercase = (value) => LOWERCASE_REGEX.test(value);
const hasNumber = (value) => NUMBER_REGEX.test(value);
const hasSymbol = (value) => SYMBOL_REGEX.test(value);
const hasUppercase = (value) => UPPERCASE_REGEX.test(value);

const validatePassword = (value) =>
  hasLowercase(value) &&
  hasNumber(value) &&
  hasSymbol(value) &&
  hasUppercase(value);

export default validatePassword;
