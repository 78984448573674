import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Offcanvas } from 'react-bootstrap';
import CreateAccount from 'components/CreateAccount';
import ForgotPassword from 'components/ForgotPassword';
import Login from 'components/Login';
import { CREATE_ACCOUNT, FORGOT_PASSWORD, LOGIN } from 'constants/account';
import { setStoreMenu } from 'stores/actions/store-menu';
import { setStoreSelectionByName } from 'stores/actions/store-selection';
import { resetCart } from 'stores/reducers/cart';
import { DRAWER_NONE, setCurrentDrawer } from 'stores/reducers/drawers';
import styles from './AccountDrawer.module.scss';

const setFocusOnModal = () =>
  document.querySelector('#account_drawer_container')?.focus();
const focusOnModal = () => setTimeout(setFocusOnModal, 250);

export default function AccountDrawer({
  changePasswordContent,
  confirmationCodeContent,
  createAccountContent,
  createAnAccountLabel,
  forgotPasswordContent,
  loginContent,
  showDrawer,
}) {
  const dispatch = useDispatch();

  const [showType, setShowType] = useState(LOGIN);

  const handleDrawerClose = useCallback(() => {
    setShowType(LOGIN);
    dispatch(setCurrentDrawer(DRAWER_NONE));
  }, [dispatch]);

  const handleOpenLogin = useCallback(() => {
    setShowType(LOGIN);
    focusOnModal();
  }, []);

  const handleOpenCreateAccount = useCallback(() => {
    setShowType(CREATE_ACCOUNT);
    focusOnModal();
  }, []);

  const handleOpenForgotPassword = useCallback(() => {
    setShowType(FORGOT_PASSWORD);
    focusOnModal();
  }, []);

  const handleOnSuccess = useCallback(
    (userInfo) => {
      if (!userInfo) {
        return;
      }

      const { preferredStore } = userInfo;
      if (preferredStore && preferredStore !== '') {
        dispatch(setStoreMenu('recreational'));
        dispatch(resetCart());
        dispatch(setStoreSelectionByName(preferredStore, 'pickup'));
      }

      handleDrawerClose();
    },
    [dispatch, handleDrawerClose]
  );

  useEffect(() => {
    document
      ?.querySelector('#backdrop')
      ?.addEventListener('click', handleDrawerClose);

    return () => {
      if (document.querySelector('#backdrop')) {
        document
          .querySelector('#backdrop')
          .removeEventListener('click', handleDrawerClose);
      }
    };
  }, [handleDrawerClose, showDrawer]);

  return (
    <Offcanvas
      show={showDrawer}
      aria-label="Account Drawer"
      className={styles.drawer_offcanvas}
      id="account_drawer"
      name="account_drawer"
      placement="end"
      onHide={handleDrawerClose}
    >
      <div className="d-flex flex-column h-100 w-100">
        <div className="d-flex flex-row align-items-end justify-content-end pt-4 px-6 w-100">
          <Button
            className="p-0"
            variant="none"
            type="button"
            id="account_drawer_close"
            title="Account Drawer Close"
            onClick={handleDrawerClose}
          >
            <span>
              <FontAwesomeIcon className={styles.close_icon} icon={faXmark} />
            </span>
          </Button>
        </div>
        <div
          id="account_drawer_container"
          className="pb-4 px-8 px-md-15 h-100 d-flex flex-column overflow-auto"
        >
          {showType === LOGIN && (
            <Login
              handleOpenCreateAccount={handleOpenCreateAccount}
              handleOpenForgotPassword={handleOpenForgotPassword}
              loginContent={loginContent}
              pageViewTitle="Login Drawer"
              onSuccess={handleOnSuccess}
            />
          )}
          {showType === CREATE_ACCOUNT && (
            <CreateAccount
              createAccountContent={createAccountContent}
              createAnAccountLabel={createAnAccountLabel}
              handleOpenLogin={handleOpenLogin}
              onLoginSuccess={handleOnSuccess}
            />
          )}
          {showType === FORGOT_PASSWORD && (
            <ForgotPassword
              changePasswordContent={changePasswordContent}
              confirmationCodeContent={confirmationCodeContent}
              createAccountContent={createAccountContent}
              forgotPasswordContent={forgotPasswordContent}
              focusOnModal={focusOnModal}
              handleOpenLogin={handleOpenLogin}
            />
          )}
        </div>
      </div>
    </Offcanvas>
  );
}
